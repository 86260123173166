/**
 * Navbar collapse
 */

export default (() => {
  document.addEventListener('click', function (event) {
    const navbarExpand = document.querySelector('.navbar-collapse.show'),
      navbarLink = event.target.matches('.nav-link[data-scroll]'),
      navbarToggler = document.querySelector('.navbar-toggler')

    if (navbarExpand && navbarLink && navbarExpand.contains(event.target)) {
      navbarExpand.classList.remove('show')
      navbarToggler.setAttribute('aria-expanded', 'false')
    }
  })
})()
