/**
 * Bootstrap form validation
 */

export default (() => {
  const selector = 'needs-validation'

  window.addEventListener(
    'load',
    () => {
      let forms = document.getElementsByClassName(selector)
      /* eslint-disable no-unused-vars, no-undef */
      let validation = Array.prototype.filter.call(forms, (form) => {
        form.addEventListener(
          'submit',
          (e) => {
            if (form.checkValidity() === false) {
              e.preventDefault()
              e.stopPropagation()
            }
            form.classList.add('was-validated')
          },
          false
        )
      })
      /* eslint-enable no-unused-vars, no-undef */
    },
    false
  )
})()
