/**
 * Navbar collapse
 */

export default (() => {
  /* eslint-disable no-unused-vars, no-undef */
  let animationToggle = document.querySelector('[data-aos]')
  if (animationToggle === null) return
  AOS.init()
  /* eslint-enable no-unused-vars, no-undef */
})()
